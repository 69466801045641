import React from 'react';
import {useQuery, gql} from '@apollo/client';

const QUERY = gql`
query Query {
  books { title author }
}
`;

const Index = () => {
    const {loading, error, data} = useQuery(QUERY);
    console.log(loading, error, data,);
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error...</div>;
    return <div>Got Data: {data.books.map(({title, author}) => (<div>{title},{author}</div>))}</div>;
};

export default Index;